.ui-paging-container {
    /*color: #666;*/
    font-size: 12px;
}

.ui-paging-container ul {
    overflow: hidden;
    text-align: center;
}

.ui-paging-container ul,
.ui-paging-container li {
    list-style: none;
}

.ui-paging-container li {
    display: inline-block;
    padding: 3px 6px;
    margin-left: 5px;
    /*color: #666;*/
}

.ui-paging-container li.ui-pager {
    cursor: pointer;
    /*border: 1px solid #ddd;*/
    border-radius: 2px;
}


/*.ui-paging-container li.ui-pager:hover,
.ui-paging-container li.focus{
    background-color: #288df0;
    color: #FFFFFF;
}*/

#nextpage:hover,
#nextpage.focus {
    background: url(../img/icon_nextpage_select.4f12d1.png);
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
}

#previouspage:hover,
#previouspage.focus {
    background-image: url(../img/icon_previouspage_select.9b1ee7.png);
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
}

#firstpage:hover,
#firstpage.focus {
    background-image: url(../img/icon_firstpage_select.acf29b.png);
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
}

#lastpage:hover,
#lastpage.focus {
    background-image: url(../img/icon_lastpage_select.4cacac.png);
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
}

.ui-paging-container li.ui-paging-ellipse {
    border: none;
}

.ui-paging-container li.ui-paging-toolbar {
    padding: 0;
}

.ui-paging-container li.ui-paging-toolbar select {
    height: 22px;
    /*border: 1px solid #ddd;*/
    /*color: #666;*/
}

.ui-paging-container li.ui-paging-toolbar input {
    line-height: 20px;
    height: 20px;
    padding: 0;
    /*border: 1px solid #ddd;*/
    text-align: center;
    width: 30px;
    margin: 0 0 0 5px;
    vertical-align: middle;
}

.ui-paging-container li.ui-paging-toolbar a {
    vertical-align: middle;
    text-decoration: none;
    display: inline-block;
    height: 20px;
    /*border: 1px solid #ddd;*/
    vertical-align: top;
    border-radius: 2px;
    line-height: 20px;
    padding: 0 3px;
    cursor: pointer;
    margin-left: 5px;
    /*color: #666;*/
}

.ui-paging-container li.ui-pager-disabled,
.ui-paging-container li.ui-pager-disabled:hover {
    cursor: default;
    border: none;
    /*background-color: #f6f6f6;
    cursor: default;
    border: none;
    color: #ddd;*/
    width: 30px;
    height: 30px;
}

#nextpage {
    background-image: url(../img/icon_nextpage.cd2d5d.png);
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
}

#previouspage {
    background-image: url(../img/icon_previouspage.17c400.png);
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
}

#firstpage {
    background-image: url(../img/icon_firstpage.d995d8.png);
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
}

#lastpage {
    background-image: url(../img/icon_lastpage.2d60a5.png);
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
}