/*
 * 全局公用样式表
 * 
 */


/*下拉列表控件*/


.global-select-title {
    color: #a4a4a4;
    float: left;
}

.global-select-value {
    float: right;
}

.global-select-img {
    float: right;
    position: relative;
    top: 7px;
    margin-left: 5px;
}

.global-select-default-option {
    border: 1px solid orange;
    position: absolute;
    width: 100%;
    display: none;
}

.qy-row {
    position: relative;
    margin: 5px 0px;
    vertical-align: middle;
}

.qy-inline {
    position: relative;
    float: left;
    display: inline;
    margin: 0px 15px 0px 0px;
}

.qy-label-inline {
    float: left;
    display: block;
    padding: 9px 15px 9px 0px;
    max-width: 500px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.qy-span-inline {
    vertical-align: text-top;
    display: inline-block;
    padding: 9px 0px 9px 15px;
    max-width: 460px;
    font-weight: 400;
    line-height: 20px;
    vertical-align: middle;
    text-align: left;
    word-break: break-all;
    word-wrap: break-word;
}

.style-input-inline {
    position: relative;
    float: left;
    display: inline-block;
    vertical-align: middle;
    min-width: 165px;
}

.qy-notInput-inline-input {
    border: none;
    outline: none;
    width: 100%;
    background: none;
    color: #000;
    font-size: 1em;
    /* height: 45px; */
    margin-left: 5px;
}

.qy-notInput-inline {
    height: 38px;
    line-height: 38px;
    cursor: pointer;
    border-bottom: 1px solid #e2e2e2;
}

/* select start */
.qy-select {
    padding: 1px 1px 1px 15px;
    height: 34px;
    width: 110px;
    border: 1px solid #C4D0E1;
    background: url(../img/selectTab.79285d.png);
    background-position-x: calc(100% - 10px);
    background-position-x: -moz-calc(100% - 10px);
    background-position-x: -webkit-calc(100% - 10px);
    background-position-y: 11px;
    background-color: #f3f3f3;
    background-repeat: no-repeat;
    color: #768292;
    font-size: 1em;
    font-family: "Microsoft YaHei";
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    cursor: pointer;

}

select::-ms-expand {
    display: none;
}

.qy-select::-ms-expand {
    display: none;
}

.search-1-select-content-btn {
    position: relative;
    right: 5px;
    height: 34px;
    width: 170px;
}

/* select end */

/* textarea start */
.qy-textarea {
    width: 300px;
    height: 80px;
    padding: 1px 1px 1px 10px;
    margin: 0px;
    font-size: 1em;
    font-family: "Microsoft YaHei";
    color: #000;
    background-color: #fff;
    border: 1px solid #C4D0E1;
    display: block;
    position: relative;
    outline: none;
    resize: none;
}

.is-resize {
    resize: none;
}

/* textarea end */


/* 按钮start */
.qy-btn {
    border-radius: 5px;
    margin: 0px 2px;
    width: 100%;
    height: 40px;
    outline: none;
    cursor: pointer;
    color: #fff;
}

.qy-btn_blue {
    border: 1px solid #108ee9;
    background-color: #108ee9;
}

.qy-btn_blue:link {
    background-color: #108EE9;
    border: 1px solid #108ee9;
}

/* 未访问链接*/
.qy-btn_blue:hover {
    background-color: #66b1ff;
    border: 1px solid #66b1ff;
}

/* 鼠标移动到链接上 */
.qy-btn_blue:active {
    background-color: #196eab;
    border: 1px solid #196eab;
}


.qy-btn_red {
    background-color: #f56c6c;
    border: 1px solid #f56c6c;
}

.qy-btn_red:link {
    background-color: #f56c6c;
    border: 1px solid #f56c6c;
}


/* 未访问链接*/
.qy-btn_red:hover {
    background-color: #f78989;
    border: 1px solid #f78989;
}

/* 鼠标移动到链接上 */
.qy-btn_red:active {
    background-color: #f55151;
    border: 1px solid #f55151;
}

.qy-btn_green {
    background-color: #67c23a;
    border: 1px solid #67c23a;
}

.qy-btn_green:link {
    background-color: #67c23a;
    border: 1px solid #67c23a;
}

/* 未访问链接*/
.qy-btn_green:hover {
    background-color: #85ce61;
    border: 1px solid #85ce61;
}

/* 鼠标移动到链接上 */
.qy-btn_green:active {
    background-color: #63b938;
    border: 1px solid #63b938;
}




.qy-btn_sm {
    width: 100px;
    padding: 0px 10px;
}

.qy-btn_md {
    width: 235px;
    margin: 0px auto;
}

.qy-btn_lg {
    /* 普通大按键 */
    display: block;
    width: 335px;
    margin: 0px auto;
}

/* 动画按钮 start */
.qy-btn_animate {
    border: 1px solid #108ee9;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    min-width: 110px;
    /* width: auto; */
    min-height: 35px;
    background-color: #fff;
    display: inline-block;
    /* padding-bottom: 15px; */
    font-size: 17px;
    color: #108ee9;
    text-align: center;
    padding: 0px 5px;
    white-space: nowrap;
}

.qy-btn_animate2 {
    min-width: 90px;
}

.qy-btn_animate:hover {
    background-color: #108ee9;
    color: #fff;
}

/* 动画按钮 end */

/* 圆形按钮 start */
.qy-btn-circle_sm {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    line-height: 1;
}

.qy-btn-circle_md {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    line-height: 1;
}

.qy-btn-circle_lg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    line-height: 1;
}

/* 圆形按钮 end */
/* 图标按钮 start */
.qy-icon {
    display: inline-block;
    speak: none;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1;
    vertical-align: baseline;
}

.qy-icon_lg {
    font-size: 30px !important;
}

.qy-icon-detial::before {
    content: "x";
    /* background: url('img/gTree/user_info_2.png') */
}

/* 图标按钮 end */


/* 文字按钮 start */
.qy-text-btn {
    color: #108EE9;
    text-decoration: underline #108EE9;
    position: relative;
    cursor: pointer;
    /* top: 1px; */
}


.qy-text-btn:link {
    color: #108EE9;
    text-decoration: underline #108EE9;
}

.qy-text-btn.is_yellow {
    color: #f6c718;
    text-decoration: underline #f6c718;
}

/* 未访问链接*/
.qy-text-btn:hover {
    color: #66b1ff;
    text-decoration: underline #66b1ff;
}

/* 鼠标移动到链接上 */
.qy-text-btn:active {
    color: #196eab;
    text-decoration: underline #196eab;
}

/* 鼠标点击时 */
/* 文字按钮 end */

/* 图片按钮 start */
.icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}

.qy-img-btn {
    background-color: #fff;
    border-radius: 5px;
}

.qy-img-btn:link {
    background-color: #fff;
}

/* 未访问链接*/
.qy-img-btn:hover {
    background-color: #e6e1e1;
}

/* 鼠标移动到链接上 */
.qy-img-btn:active {
    background-color: #d6cccc;
}

/* 图片按钮 end */

/* 按钮end */


.dialog_content {
    padding: 10px 25px 0px 25px;
    display: none;
    overflow-x: hidden;

    height: 100%;
}

.qy_form {}

.qy_form.is_inline {
    display: inline-block;
    position: relative;
}

.qy_form_item {
    height: 35px;
    margin-bottom: 10px;
}

.qy_form_item input[type=text] {
    float: right;
    text-align: center;
    height: 35px;
    width: 230px;
    color: #555;
    background-color: #f3f3f3;
    margin-left: 10px;
}

.qy_form_item_span {
    font-weight: 100;
    position: relative;
    top: 7px;
    font-size: 1em;
}

.qy_form_item textarea {
    float: right;
    height: 50px;
    width: 230px;
    margin-left: 10px;
    border: 1px solid #c4d0e1;
    outline: none;
    resize: none;
    background-color: #f3f3f3;
    color: #555;
    font-size: 1em;
    text-align: center;
    padding: 5px 10px;
}


.qy_form_item select::-ms-expand {
    display: none;
}

.qy_form_item select {
    float: right;
    position: relative;
    left: 1px;
    margin-left: 10px;
    height: 35px;
    width: 230px;
    background-color: #f3f3f3;
    background-position-x: 198px;

}

.qy_form_item_span {
    font-weight: 100;
    position: relative;
    top: 7px;
    font-size: 1em;
}

.qy_form_item_radioandimg {
    display: inline-block;
    float: right;
    height: 35px;
    width: 270px;
}

.qy_form_item_radioandimg .radio {
    display: inline-block !important;
    width: 120px;
}

/* 搜索框 start */
.qy_input_group {
    position: relative;
    display: table;
    border-collapse: separate;
    padding: 0px 5px;
    margin: 12px 0px;
    border-radius: 4px;
    width: 100%;
    font-size: 16px;
}

.qy-input,
.qy_input_group input {
    position: relative;
    /* margin: 0px; */
    float: right;
    height: 34px;
    padding: 1px 1px 1px 10px;
    border: 1px solid #C4D0E1;
    outline: none;
    font-size: 1em;
    font-family: "Microsoft YaHei";
}

.qy-input_inner {
    width: 100%;
    border-radius: 4px 0px 0px 4px;
}

.qy_input_group--append {
    display: table-cell;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-left: none;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
    font-size: 1em;
    cursor: pointer
}

/* 搜索框 end */


/*checkbox相关 start*/

input[type=checkbox] {
    position: relative;
    visibility: hidden;
}

input[type=checkbox]+label {
    /* float: left; */
    /* position: relative; */
    /* bottom: 10px; */
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    display: block;
    border-radius: 50%;
    visibility: visible;
    text-align: center;
    content: "";
    width: 20px;
    height: 20px;
    background: url(../img/icon-checkBox.5cc786.png) no-repeat;
}

input[type=checkbox]:checked+label {
    background: url(../img/icon-checkBox-select.b8d7ff.png) no-repeat;
}



/* checkbox相关 end */


/* item start */
.qy-list-item {
    border-bottom: 1px dashed #C4D0E1;
    padding: 5px;
    position: relative;
    overflow: hidden;
}


.qy-list-item:hover {
    background-color: #f9f9f9;
}

.qy-list-item.is_lg {
    height: 80px;
    min-width: 367px;
    padding: 15px 5px;
    cursor: pointer;
}

.qy-list-item_lg:nth-last-of-type(1) {
    border-bottom: 0px;
}

.qy-list-item-checkbox {
    display: inline-block;
    position: relative;
    /* left: 5px;
    bottom: 13px; */
    width: 20px;
    height: 20px;
    vertical-align: middle;
}

.qy-list-item-checkbox.is_lg {
    left: 10px;
    top: 2px;
}

.checkboxall_span {
    vertical-align: middle
}

.qy-list-item-logo {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
}

.qy-list-item-main {
    display: inline-block;
    margin: auto 10px;
    width: 85%;
    position: relative;
    /* bottom: 2px; */
    vertical-align: middle;
}

.qy-item-active {
    background: #BFEFFF;
}

.qy-item-select {
    background-color: #ebebeb;
}


.qy-item-span {
    position: relative;
    top: 6px;
    /* vertical-align: middle; */
    display: inline-block;
    width: 180px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.qy-item-btn {
    cursor: pointer;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin: 5px 8px 5px 0px;
    float: right;
    vertical-align: middle;
}

.qy-item-btn.is_lg {
    width: 30px;
    height: 30px;
}

.list_item_icon_wrapper {
    width: 70px;
    height: 50px;
    position: relative;
    bottom: 22px;
    left: 30px;
    padding: 0px 10px;
}

.list_item_icon {
    /*vertical-align: top;*/
    width: 50px;
    height: 50px;
    position: relative;
    background-image: url("https://me-oss.oss-cn-qingdao.aliyuncs.com/web/Square-icon.png");
    /* top: 8px; */
}

.list_item_describe {
    font-size: 1em;
    font-family: "Microsoft YaHei";
    position: relative;
    left: 105px;
    bottom: 95px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 250px;
    color: #999;
    display: block;
}

.list_item_time {
    font-size: 0.75em;
    position: relative;
    bottom: 97px;
    left: 240px;
    color: #999;
    margin-top: 20px;
    font-family: "Adobe Heiti Std";
}

.list_item_name {
    font-size: 1em;
    font-family: "Microsoft YaHei";
    position: relative;
    bottom: 75px;
    left: 105px;
    display: inline-block;
    width: 130px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
}

/* item end */


/* layui tips css start */
.li-popover {
    padding: 3px 0px;
    border-bottom: 1px dashed #C4D0E1;
    list-style: none;
    cursor: pointer;
    color: #000
}

/* layui tips css end*/


/* 显示搜索时间模块start */
.time-search-hint {
    background-color: #b7b7b7;
    color: #fff;
    text-align: center;
}

.exit-timeSearch-img {
    float: right;
    position: relative;
    right: 10px;
    top: 10px;
    width: 40px;
    height: 40px;
}

.time-search-hint label {
    width: 75%;
}

.time-search-hint-title {
    font-size: 1em;
    font-weight: 100;
    float: left;
    position: relative;
    /* left: 40px; */
}

.time-search-hint-time {
    font-size: 1em;
    font-weight: 100;
    position: relative;
    width: 165px;
    display: inline-block;
}

/* 显示搜索时间模块end */

/* 消息菜单详情 start */
.msg_detial_img {
    cursor: pointer;
    position: relative;
    bottom: 18px;
    left: 100px;
    width: 25px;
    height: 25px;
}


.msg_detial_delete {
    left: 90px;
}

/* 消息菜单详情 end*/

/* 单独属性 start */
.marginTop10px {
    margin-top: 10px;
}

.display--none {
    display: none;
}

.display--inblock {
    display: inline-block;
}

.color--blue {
    color: #108EE9;
}

.color--green {
    color: #219646;
}

.color--red {
    color: #FF6666;
}

.color--grey {
    color: #CCCCCC;
}

.color--yellow {
    color: #e4d412;
}

.color--dimgrey {
    color: #595555;

}

.bgcolor--blue {
    background-color: #108EE9
}

.bgcolor--green {
    background-color: #219646
}

.bgcolor--grey {
    background-color: #CCCCCC
}

.fontW--bold {
    font-weight: bold;
}

.fontS--25em {
    font-size: 1.25em
}

.div--square {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 5px;
    vertical-align: middle;
}

.vertical--m {
    vertical-align: middle;
}

/* 单独属性 end */

/* 滚动条start */
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/

::-webkit-scrollbar {
    width: 10px;
    height: 14px;
    background-color: #FFFFFF;
}


/*定义滚动条轨道 内阴影+圆角*/

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #FFFFFF;
}

.scroller_wrapper {
    position: absolute;
    z-index: 1;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    width: 360px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    -o-text-size-adjust: none;
    text-size-adjust: none;
}

.scroller_wrapper ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: left;
}

.scroller_pullUp,
.scroller_pullUp_flip,
.scroller_pullUp_loading {
    background: #fff;
    /* height: 45px; */
    line-height: 40px;
    padding: 5px 10px;
    /* border-bottom: 1px solid #ccc;*/
    font-weight: bold;
    font-size: 1em;
    text-align: center
}

/*定义滑块 内阴影+圆角*/

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #1D74BF;
}

/* 滚动条end */