/*
 * 主页主框架公用布局
 */

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /*font-family: "Times New Roman", Times, serif;
    */
    font-weight: 100;
    font-family: "Microsoft YaHei";
    font-size: 16px;
}

body {
    min-width: 1200px;
    _width: 1200px;
    overflow-x: auto;
    overflow-y: hidden;
    font-family: "Microsoft YaHei" !important;
    font-size: 16px;
}

/* 设置了浏览器宽度不大于500px时 abc */

/*顶部信息栏start*/

#header {
    height: 70px;
    width: auto;
    background-color: #1d75bf;
    overflow: hidden;
    border-bottom: 1px solid #1d75bf;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    color: #fff;
}


.header_logo {
    /* margin-top: 10px;
    margin-left: 10px; */
    padding-left: 20px;
    /* position: relative; */
    display: inline-block;
    /* height: 100%; */
    /* vertical-align: middle; */
}

.header_logo img {
    /* vertical-align: middle;
    position: relative;
    bottom: 5px; */
    margin-right: 10px;
    vertical-align: text-bottom;
    max-height: 50px;
}

.header_logo span {
    position: relative;
    margin: 0px;
    font-size: 1.6em;
    font-weight: bold;
    color: #fff;
}

.header_logo_title11 {
    margin: 0px;
    font-size: 1em;
    font-weight: bold;
    color: #fff;
    /* width: 319px; */
}

.header_login_user_info {
    /* margin-top: 20px; */
    margin-left: 30px;
    margin-right: 30px;
    color: white;
    display: inline-block;
    /* position: absolute;
    right: 350px; */
}

.header_login_user_info .header_login_name_span {
    color: white;
    font-size: 1em;
}


#header_login_operator_name {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 80px;
    vertical-align: top;
}



/*顶栏右边菜单栏start*/

/* .header_right_bar {
    position: absolute;
    right: 5px;
   
    display: inline-block;
    padding-top: 10px;
    
    height: 70px;
    color: white;
} */
.header_right_bar {
    margin-right: 5px;

    display: inline-block;
    padding-top: 10px;

    height: 70px;
    color: white;
}

.header_right_bar_item,
.header_right_bar_item_last {
    /*padding-right: 5px;
    padding-left: 5px;*/
    position: relative;
    /* bottom: 5px; */
    display: inline-flex;
    flex-direction: column;
    max-width: 90px;
    margin: 0 5px 0 0;
    align-content: baseline;
    justify-content: center;
    align-items: center;
}

.header_right_title,
.header_right_title:hover,
.header_right_title:focus,
.header_right_title:active {
    display: inline-block;
    color: #fff;
    max-width: 100%;
    font-size: 0.7em;
    font-weight: 4;
    height: 40px;
}

.header_right_bar_item div {
    width: 35px;
    height: 35px;
    display: inline-block;
}

.header_right_bar_item a {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
}

.header_right_bar_item_last div {
    width: 35px;
    height: 35px;
    display: inline-block;
}

.poi_wrap {
    border: 1px solid #4a494a;
    position: absolute;
    right: 0px;
    top: 90px;
    z-index: 100;
    background-color: white;
    padding: 10px;
    display: none;
}

/*顶栏右边菜单栏end*/

/*地图切换菜单start*/

.map_switch_wrap {
    position: absolute;
    display: block;
    border: 1px solid #C4D0E1;
    z-index: 90;
    background-color: white;
}

#map_layers_switch {
    right: 10px;
    top: 100px;
}

#map_type_switch {
    right: 60px;
    bottom: 25px;
}

.map_switch_btn {
    border: none;
    outline: none;
    cursor: pointer;
    color: black;
    padding: 7px;
    background-color: white;
    margin: 0px auto;
    font-size: 12px;
}

/*地图切换菜单end*/

/*监控子菜单start*/

.h-r-menu-sub-monitor-content {
    border: 1px solid #b4a692;
    position: absolute;
    right: 185px;
    top: 60px;
    z-index: 100;
    background-color: white;
    padding: 5px;
    border-radius: 4px;
    display: none;
}

.h-r-monitor-item {
    border-bottom: 1px dashed #e0e0e0;
    padding: 2px;
    font-size: 12px;
    cursor: pointer;
}

.h-r-monitor-item:hover {
    background-color: #dedede;
}

.h-r-monitor-top-qipao {
    border-bottom: 7px solid #b4a692;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    position: absolute;
    right: 35px;
    top: -7px;
}

/*监控子菜单end*/

/*顶部信息栏end*/

#main_content {
    position: relative;
    overflow: hidden;
    width: 100%;
    border: none;
}

/*最右侧监控管理菜单显示容器start*/


.monitor_item_name,
.unmonitor_item_name {
    width: 140px;
}


.noLocPermission {
    color: #636161;
}

/*最右侧监控管理菜单显示容器end*/




/*左侧主菜单start*/

.sidebar {
    height: 100%;
    width: 110px;
    background-color: #25344b;
    overflow: hidden;
    position: absolute;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    overflow-y: auto;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    z-index: 201;
}

.sidebar::-webkit-scrollbar {
    width: 0px
}

.nav_item {
    color: #6d7a8b;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 2px groove #3f587f;
    cursor: pointer;
    position: relative;
    font-size: 1em;
    width: 100px;
    /* height: 100px; */
    vertical-align: middle;
}

.nav_item label {
    font-weight: 100;
    font-size: 1em;
}

.nav_item_content {
    height: 66px;
    border-left: 7px solid #25344b;
    line-height: 1
}

#nav_helper_icon {
    background-position: 0px 0px;
}

#nav_org_icon {
    background-position: -43px 0px;
}

#nav_group_icon {
    background-position: -90px 1px;
}

#nav_fence_icon {
    background-position: -143px 0px;
}

#nav_mediarecord_icon {
    background-position: -197px 3px;
}

#nav_task_icon {
    background-position: -249px 1px;
}

#nav_videoconf_icon {
    background-position: -299px 1px;
}

#nav_uikit_icon {
    background-position: -245px 44px;
}

.nav_item_icon {
    width: 43px;
    height: 44px;
    display: inline-block;
    background-image: url(../img/left_menu_icon.849b7f.png);
}


.msg_read_tips {
    position: absolute;
    right: 25px;
    top: 15px;
    z-index: 1;
    display: none;
}

#left_page_container {
    width: auto;
    height: 100%;
    background-color: #FFFFFF;
    display: none;
    position: absolute;
    z-index: 200;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}



#map_content {
    /* min-width: 1200px; */
    /*930px*/
    /* _width: 1200px; */
    height: 100%;
    position: absolute;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.menu-show-close-btn {
    display: inline-block;
    position: absolute;
    right: -32px;
    top: 100px;
    cursor: pointer;
}

.menu-show-close-btn img {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.left-menum-more-btn {
    color: white;
    position: absolute;
    bottom: 6px;
    left: 15px;
    cursor: pointer;
    display: none;
}

.left-menum-more-btn-active {
    color: #4a494a;
}

.menu-more-content {
    position: absolute;
    top: -155px;
    left: -12px;
    text-align: center;
    width: 60px;
    background-color: #4a494a;
    border-radius: 5px;
    display: none;
}

.menu-more-item {
    font-size: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    border-bottom: 1px dashed #e0e0e0;
}

.menu-more-item-last {
    font-size: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
}

.bottom-qipao {
    border-top: 7px solid #4a494a;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    position: absolute;
    right: 24px;
    bottom: -7px;
}

/*左侧主菜单end*/

/*修改密码弹出框start*/

.resetPwdBox {
    width: 370px;
    height: 360px;
    margin: 0px auto;
    overflow: hidden;
    background-color: white;
    position: relative;
    border: 2px solid #4a495a;
    border-radius: 5px;
    padding-bottom: 20px;
}

.closePwdBoxBtn {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.pwdBoxTitle {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.15em;
    height: 40px;
    font-weight: 100;
    background-color: #25344b;
    color: #fff;
}

.pwdBoxTitle span {
    font-size: 20px;
}


#oldPwd,
#newPwd,
#sureNewPwd {
    padding: 1px;
    text-align: center;
    font-size: 10px;
    /*display: block;*/
    height: 35px;
    width: 330px;
    line-height: 1.42857143;
    color: #555;
    background-color: #F3F3F3;
    border: 1px solid #fff;
    border: 1px solid #C4D0E1;
    font-size: 12pt;
    font-family: "Microsoft YaHei";
    display: inline-block;
    margin-left: 10px;
}

.set_pwd_input {
    font-size: 1em;
}

.psw_error_span {
    position: relative;
    float: right;
    right: 10px;
    color: red;
}



/*修改密码弹出框end*/

.cursor-style {
    cursor: pointer;
}

/*loading动画start*/

.body-loading-gif {
    z-index: 2000;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    text-align: center;
    display: block;
    background-color: white;
}

.body-loading-gif-show {
    width: 400px;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
}

.loading-gif {
    z-index: 200;
    width: 100px;
    height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    display: none;
}

.loading-gif-white {
    z-index: 500;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: white;
    display: none;
}

.loading-gif-show {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
}

/*loading动画start*/


/*对讲机界面start*/


.talk_status_span {
    font-size: 1em;
    color: #1994e2;
    /* position: relative; */
    /* bottom: 50px; */
    margin-bottom: 10px;
    word-break: break-all;
}

.talk_btn_wrap {
    width: 88px;
    height: 88px;
    position: relative;
    /* left: 30%; */
    margin: auto;
    /* bottom: 20px; */
}

.talk_content {
    border-bottom: 2px solid #e0e0e0;
    display: none;
}

.talkie-menu-main {
    text-align: center;
}


/*对讲机界面end*/

/*监控用户地图信息start*/


.amap-info-content,
.amap-info-outer {
    padding: 0;
}

.MAP_info_dialog_header {
    height: 40px;
    width: 330px;
    background-color: #25344b;
    color: #fff;
}

.MAP_info_dialog_title {
    font-size: 20px;
    margin: 5px 20px;
    float: left;
    display: inline-block;
    width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.MAP_info_dialog_close {
    float: right;
    margin-right: 20px;
    font-size: 30px;
}


.MAP_info_dialog_content {
    border-top: 2px solid #25344b;
    padding: 5px 15px;
    width: 330px;
}


.MAP_info_wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.MAP_info_title {
    position: relative;
    /* left: 15px; */
    /* top: 7px; */
    display: inline-block;
    margin-top: 2px;
    padding: 4px;
    width: 80px;
    height: 100%;
    background-color: #25344b;
    border-radius: 5px 0px 0px 5px;
    color: #ffffff;
    font-size: 1em;
    text-align: center;
}

.MAP_info_content {
    position: relative;
    /* left: 15px; */
    /* top: 7px; */
    margin-top: 2px;
    padding: 4px;
    display: inline-block;
    border-radius: 0px 5px 5px 0px;
    height: 100%;
    width: calc(100% - 80px);
    width: -moz-calc(100% - 80px);
    width: -webkit-calc(100% - 80px);
    background-color: #f1f1f1;
    text-align: center;
    font-size: 1em;
    color: #1994e2;

}


.MAP_info_monitor_hide_img {
    display: none;
}


.MAP_info_dialog_icon {
    position: relative;
    float: right;
    width: 30px;
    height: 30px;
    margin-right: 7px;
    margin-top: 5px;
    cursor: pointer;
}

.amap-marker-label-indoor {
    color: #000;
    font-weight: bold;
    background-color: #fcf29f;
    border: 1px solid #efb589;
}

.amap-marker-label-outdoor {
    color: #000;
    font-weight: bold;
    background-color: #3ed6eb;
    border: 1px solid #efb589;
}

.amap-marker-label-offline {
    color: #000;
    background-color: #b4b6b6;
    border: 1px solid #efb589;
}

.amap-marker-label {
    color: #000;
    background-color: #cfcdca;
    border: 1px;
    padding: 1px;
    height: 110%;
}

.amap-info-content {
    width: 330px;
    /* height: 220px; */
    padding: 0px;
    overflow: hidden;
}

.amap-info-close,
.amap-info-close:hover,
.amap-info-close:hover:after {
    font-size: 45px;
    font-weight: 50;
    color: #fff;
    padding-top: 5px;
    left: 290px;
    text-decoration: none;
}

/*监控用户地图信息end*/

/*通用图标end*/

.circle_icons {
    position: relative;
    /* top: 55px;
    right: 120px; */
    display: inline-block;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-image: url("https://me-oss.oss-cn-qingdao.aliyuncs.com/web/Circular-icons.png");
}

.circle_icons.is_edit {
    background-position: -243px -4px;
}

.circle_icons.is_user {
    background-position: -147px -5px;
}

.circle_icons.is_delete {
    background-position: -195px -5px;
}


.circle_icons.is_right {
    /* right: 190px; */
    background-position: -52px -4px;
}

.circle_icons.is_upload {
    /* right: 100px; */
    background-position: -100px -4px;
}

.circle_icons.is_records {
    /* top: 55px; */
    background-position: -4px -4px;
}





.header_right_icon {
    background-image: url("https://me-oss.oss-cn-qingdao.aliyuncs.com/web/top_right_icon.png");
}

/*通用图标end*/

/*layui相关 start*/

.layui-laydate-content {
    position: relative;
    padding: 5px;
}

.laydate-btns-time {
    height: 26px;
    line-height: 26px;
    margin: 0 0 0 -1px;
    padding: 0 10px !important;
    border: 1px solid #108ee9;
    background-color: #fff;
    white-space: nowrap;
    vertical-align: top;
    border-radius: 2px;
    color: #108ee9;
    font-weight: 600;
}

.layui-laytime-close {
    position: absolute;
    right: 10px;
    top: 0;
    line-height: 1;
    cursor: pointer;
    border: none;
    font-size: 40px !important;
    text-align: center;
}

.layui-layer {
    border-radius: 6px !important;
}

.layui-layer-title {
    border-radius: 6px 6px 0 0 !important;
    font-size: 1.25em !important;
}

.self-confirm {
    position: relative;
    padding: 15px 20px;
    line-height: 24px;
    word-break: break-all;
    overflow: hidden;
    font-size: 14px;
    overflow-x: hidden;
    overflow-y: auto;
}

.layui-colorpicker {
    /* 颜色选择器 */
    width: 38px;
    height: 38px;
}

.layui-colorpicker-main {
    box-sizing: content-box
}

.layui-colorpicker-main-input input.layui-input {
    width: 100px;
}

.layui-laydate-footer {
    padding: 10px 5px !important;
}

.layui-table-tips-c:before {
    position: relative;
    right: 1px;
    bottom: 2px;
}

/*layui相关 end*/

.noinfo {
    text-align: center;
}


.upload_a,
.upload_a:hover,
.upload_a:focus,
.upload_a:active {
    position: relative;
    display: inline-block;
    background: #108ee9;
    border: 1px solid #108ee9;
    border-radius: 4px;
    padding: 4px 12px;
    overflow: hidden;
    color: #fff;
    text-decoration: none;
    text-indent: 0;
    line-height: 20px;
}

.upload_a input {
    position: absolute;
    font-size: 100px;
    right: 0;
    top: 0;
    opacity: 0;
}

.template_download_btn,
.template_download_btn:hover,
.template_download_btn:focus,
.template_download_btn:active {
    display: inline-block;
    text-decoration: none;
    background: #108ee9;
    border-radius: 4px;
    padding: 4px 12px;
    overflow: hidden;
    color: white;
}

.import_excel_data_wrap {
    height: -moz-calc(100% - 126px);
    height: -webkit-calc(100% - 126px);
    height: calc(100% - 126px);
}

/* header 模块 start */
.content_header_wrap {
    background-color: #f8f8f8;
    height: 45px;
    color: #108ee9;
    text-align: center;
    letter-spacing: 2px;
    font-weight: bold;
}

.content_header_title {
    position: relative;
    top: 22%;
    font-size: 1.15em;
}



.content_header_close {
    cursor: pointer;
    width: 25px;
    height: 25px;
    float: right;
    margin: 10px 5px;
}


.content_header_wrap.is_search {
    height: 46px;
    border-bottom: 1px solid #C4D0E1;
    background-color: #fff;
    color: #555;
}


/* header 模块 end */
/* footer模块 start */
.content_footer_wrap {
    padding: 10px;
    height: 97px;
    overflow: hidden;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-shadow: 1px 1px 1px #C4D0E1;
    border-top: 1px solid #C4D0E1;
    position: relative;
}

.footer_2btn_wrap {
    position: relative;
    width: 280px;
    margin: auto;
    margin-top: 10px
}

.footer_1btn_wrap {
    position: relative;
    width: 110px;
    margin: auto;
    margin-top: 10px
}



.footer-2-pageTool {
    position: relative;
    margin: 3px auto;
}

/* footer模块 end */

/* list view start */
.page_list_wrapper {
    /* background-color: white; */
    overflow: auto;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    position: relative;

    width: 100%;
}

#org_user_wrapper {
    margin-top: 50px;
}

.page_list {
    overflow-y: auto;
    overflow-x: hidden;
    /* padding: 10px; */
    height: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

/* list view end */

/* 右侧 start */
.right_page_wrapper {
    height: 100%;
    width: 340px;
    background-color: white;
    overflow: hidden;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 201;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    display: none;
    border: 1px solid #9b9b9b;
}

/* 右侧 end */


/* 左侧 start */
.left_page_wrapper {
    display: none;
    background-color: white;
    height: 100%;
}

.left_page_sub_wrapper {
    float: left;
    background-color: white;
    width: 358px;
    display: none;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    height: 100%;
    overflow: hidden;
    border-right: 1px solid #C4D0E1;
}

/* 左侧搜索模块 start */
.page_search_container {
    padding: 10px;
    background-color: #fff;
    color: #768292;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    position: relative;
    box-shadow: 1px 1px 1px #C4D0E1;
}

.page_search_container form {
    display: inline-block;
}

.page_search_content {
    height: 32px;
    display: inline-block;
}

.page_search_content input {
    border-left: none
}

/* 左侧搜索模块 end */

/* 详情页 start */
.detial_page_header_wrap {
    height: 100px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    position: relative;
    padding: 5px 5px;
}

.detial_page_header_name {
    display: inline-block;
    width: 160px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #000000;
    font-family: "Microsoft YaHei";
    font-size: 1.25em;
    margin: 10px 0px;
    font-weight: bold;
}

.detial_page_header_time {
    color: #999999;
    font-family: "Adobe Heiti Std";
    font-size: 1em;
}

.detial_content_wrapper {
    background-color: white;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    position: relative;
    /**IF_DEFINES("ELECTRON")  */
    /* false */
    /**ENDIF  */
    /**IF_DEFINES("ELECTRON")  */
}

.detial_media_content {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px solid white;
    background-color: white;
    margin-bottom: 5px;
    position: relative;
    width: 360px;
    height: 285px;
}

.detial_picture_wrapper {
    height: auto !important;
}

.monitor-page {


    width: 100%;
    overflow-y: scroll;
}

.monitor-foot {
    position: absolute;
    bottom: 0px;
    background-color: #f1f1f1;
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#uikit_video_init_btn {
    height: 40px;
    width: 100px;
    border: solid 1px #108EE9;
    color: #108EE9;
    border-radius: 6px;
}

#uikit_video_init_btn:hover {
    color: #fff;
    background-color: #108EE9;
}

.monitorDownImg {
    height: 30px;
    width: 30px;
    margin-left: 230px;
}

.monitorType {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    height: 50px;
    padding-left: 40px;
    font-size: large;
    border-bottom: solid 1px #4a494a;
    line-height: 50px;
}

.monitorTypeItem {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    line-height: 40px;
    border-bottom: solid 1px #e0e0e0;
    padding-left: 60px;

    /*  white-space:nowrap;
   overflow:hidden;
   text-overflow:ellipsis; */
}

.monitorTypeItem-txt {
    margin-left: 15px;
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.monitorCheckbox {
    display: block;
    visibility: visible !important;
    height: 20px;
    width: 20px;
}

#playVideoDv {
    position: absolute;
    height: 500px;
    width: 500px;
    background-color: #fff;
    top: 0px;
    left: 0px;
    z-index: 100;
}

#video-top-dv {
    box-sizing: border-box;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 30px;
    background-color: #F3F3F3;
}

#changeVideoScreen {
    height: 30px;
    width: 70px;
    position: absolute;
    right: 30px;
    top: 0px;
}

#playVideoDv-close {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 30px;
    width: 30px;
    color: #FF0000;
    background-color: #D2D2D2;
    line-height: 30px;
    text-align: center;
    font-size: larger;
    cursor: pointer;
}

#video-play-dv {
    height: 90%;
    width: 100%;
    margin-top: 2%;
}

.divided {
    width: 100%;
    height: 98%;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-self: space-around;
}

.video-play-2-item {
    height: 43%;
    width: 45%;
    background-color: #000;
}

.video-play-3-item {
    height: 29%;
    width: 30%;
    background-color: #000;
}

.video-play-4-item {
    height: 21%;
    width: 23%;
    background-color: #000;
}

#Map_all_dialog {

    width: 100%;

}

/* 详情页 end */

/* 左侧 end */
#webRtc_kurento {
    height: 800px;
    width: 1500px;
    position: absolute;
    top: 0px;
    left: 0px;

    z-index: 9999;
}

#max_video_dv {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999;
    display: none;
}
#max_video_txt{
    position: absolute;
    left: 0;
    bottom: 0;
    color: #fff;
    text-shadow: #000 1px 1px ;
    z-index: 9999999;
}