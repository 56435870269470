.task_root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ts-header {
    width: 100%;
    height: fit-content;
    border-bottom: 1px solid #ccc;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.ts-header .el-input {
    width: 280px;
}

.el-select .el-input {
    width: 180px;
}

.el-button {
    margin: 0 5px;
}

.ts-operation-bar {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    padding: 10px;
}

.ts-table-header {
    background-color: #f2f2f2;
    font-weight: 100;
    font: bold;
}

.el-table .ts-review-row {
    background: #b6ee96;
    cursor: pointer;
}

.el-table .ts-ongoing-row {
    background: #96ebee;
    cursor: pointer;
}

.el-table .ts-finished-row {
    background: rgb(252, 252, 252);
    cursor: pointer;
}

.el-table .ts-new-row {
    background: rgb(241, 224, 193);
    cursor: pointer;
}

.el-table .ts-deleted-row {
    background: rgb(199, 199, 199);
    cursor: pointer;
}

.ts-row-space-between {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ts-dlg-button-bar {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 10px;
}

.ts-dialog-content {
    height: 550px;
    overflow-y: auto;
}

.ts-config-container {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;

}

.ts-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid 1px #f5f5f5;
    border-radius: 5px;
    width: 49%;
    box-sizing: border-box;
}

.ts-frame-header {
    width: 100%;
    background-color: #f5f5f5;
    text-align: center;
    font: 1.25rem;
    font-weight: bold;
    border-bottom: solid 1px #f0f0f0;
    padding: 10px 0;
}

.ts-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
}

.ts-tree {
    width: 100%;
}

.el-tree-node__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 56px;
    cursor: pointer;
}

.org-tree-node {
    height: 1.2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.custom-tree-node {
    height: 1.2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ts-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
    text-align: center;
    padding: 5px 0;
    border-bottom: solid 1px #f6f6f6;
}

.ts-list-item:hover {
    color: #409eff;
}

.ts-list-item-selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
    text-align: center;
    padding: 5px 0;
    border-bottom: solid 1px #f6f6f6;
    color: #409eff;
}