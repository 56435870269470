/*
 * 组织成员菜单
 */



/*组织结构主菜单start*/

.org_tree_content {
    background-color: white;
    overflow: auto;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}


/*组织结构主菜单end*/


/*组织结构三级菜单start*/
#org_user_footer {
    height: 135px;
    overflow: visible;
    /* visible不可缺，为了底部操作按钮能弹出dropdown-menu，不被遮挡 */
}

.dropdown-menu {
    bottom: 100%;
    top: auto !important;
}


#org_user_op_dropdown {
    position: relative;
    display: inline;
    margin: 5px;
}

/*组织结构三级菜单end*/