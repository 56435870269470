/* 
*媒体记录
 */

#media_record_checkboxall-font {
    position: relative;
    top: 7px;
}



#media_statis_content .import_excel_data_wrap {
    height: -moz-calc(100% - 60px);
    height: -webkit-calc(100% - 60px);
    height: calc(100% - 60px);
}