/*
 * 频道会话
 */

/*预定义组列表start*/


.group_list_item {
    padding-right: 14px;
    min-width: 390px;
}


.group_info_content {
    position: relative;
    border-bottom: 1px solid #C4D0E1;
    /* padding: 10px; */
    width: 390px;
    min-width: 380px;
}

.group_info_content:hover,
.group_user_item_info-op:hover {
    background-color: #BFEFFF;
}



.group_item_logo {
    position: relative;
    width: 24px;
    height: 24px;
    /* vertical-align: baseline; */
}

.group_item_name_span {
    display: inline-block;
    padding-left: 5px;
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
    text-overflow: ellipsis;
    font-size: 1.25em;
}

.group_user_wrapper {
    display: none;
    border-bottom: 1px solid #C4D0E1;
    padding-left: 37px;
    padding-right: 10px;
    padding-bottom: 10px;
    font-size: 12px;
}



.group_user_item:last-child {
    border-bottom: none;
}

.group_online_content {
    padding-top: 0px;
}

.group_user_status_img {
    position: relative;
    width: 24px;
    height: 24px;
    vertical-align: middle;
}

.group_user_item_name {
    position: relative;
    max-width: 180px;
    width: auto;
    cursor: pointer;
    font-family: "Microsoft YaHei";
    font-size: 1em;
}

.group_user_item_name_me {
    color: red;
}

.group_user_item_monitor {
    position: relative;
    left: 5px;
    margin: 5px;
    width: 20px;
    height: 20px;
}

.group_user_item_noSpeak {
    position: relative;
    top: 2px;
    margin: 5px;
    width: 20px;
    height: 20px;
}

.group_user_item_pro {
    position: relative;
    top: 2px;
    margin: 5px;
    width: 20px;
    height: 20px;
}


.group_user_item_info img {
    margin-right: 3px;
    vertical-align: middle;
}

.group_user_status {
    position: absolute;
    right: 70px;
    top: 12px;
    vertical-align: middle;
    font-size: 0.8em;
    font-weight: bolder;
}

/*群组操作*/

.group_item_fold_btn {
    position: relative;
    bottom: 40px;
    right: 30px;
    float: right;
    /* margin-right: 20px; */
    margin-left: -10px;
    margin-left: 3px;
    width: 30px;
    height: 30px;
    vertical-align: baseline;
    vertical-align: middle;
    cursor: pointer;
}


.group_name_wrap {
    padding: 10px;
}


.joined_group_toolbar_wrapper {
    display: none;
    position: relative;
    border-top: 1px solid #C4D0E1;
    height: 50px;
    background-color: #BFEFFF;
}

.joined_group_toolbar_wrapper:after,
.joined_group_toolbar_wrapper:before {
    position: absolute;
    bottom: 100%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
}

.joined_group_toolbar_wrapper:after {
    left: 12px;
    border-width: 10px;
    border-bottom-color: #BFEFFF;
}

.joined_group_toolbar_wrapper:before {
    left: 10px;
    border-width: 12px;
    border-bottom-color: #C4D0E1;
}

.group_toolbar_img {
    position: relative;
    top: 10px;
    margin-right: 5px;
    margin-left: 10px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.group_unread_msg_point {
    position: absolute;
    top: 8px;
    right: 160px;
    display: none;
}

/*成员信息提示*/

.group_user_item_pro_wrapper {
    opacity: 1.0;
}

/*点击成员名称上，显示可对该成员进行的操作*/

.group_user_item_toolbar_wrap {
    position: relative;
    /* left: 25px; */
    display: none;
    padding-top: 5px;
    border-top: 1px dashed #C4D0E1;
    border-left: 1px dashed #C4D0E1;
    border-radius: 6px 0 0 4px;
    height: 38px;
    background-color: #BFEFFF;
    line-height: 29px;
}

.group_user_item_toolbar_wrap:after,
.group_user_item_toolbar_wrap:before {
    position: absolute;
    bottom: 100%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
}

.group_user_item_toolbar_wrap:after {
    left: 7px;
    border-width: 10px;
    border-bottom-color: #BFEFFF;
}

.group_user_item_toolbar_wrap:before {
    left: 5px;
    border-width: 12px;
    border-bottom-color: #C4D0E1;
}

.group_user_item_info_content {
    position: relative;
    /* bottom: 27px;
    left: 15px; */
}


.group_user_item_btn {
    position: relative;
    left: 10px;
    bottom: 3px;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.group_user_immloc {
    width: 25px;
}
.group_user_show_in_center {
    width: 25px;
}


/*预定义组列表底部start*/
#group_list_footer_note {
    height: 30px;
    padding: 5px 0px;
}

.follow-user-id {
    padding-right: 10px;
}

/*预定义组列表底部end*/

/*预定义组列表 end*/




/*预定义组录音记录start*/

.chat_msg_box{
    height: 110px !important;
}
.chat_msg_box,
.chat_msg_box_mine {
    width: 360px;
    height: 100px;
    /*  padding-top: 10px;*/
}

.chat_msg_logo {
    position: relative;
    width: 40px;
    height: 40px;
}

.chat_msg_box_mine .chat_msg_logo {
    top: 25px;
}

.chat_msg_box .chat_msg_logo {

    top: 15px
}

.chat_msg_box_content {
    position: relative;
    bottom: 40px;
    left: 40px;
    padding-left: 5px;
   /*  height: 140px; */
    width: 280px;
    overflow: hidden;
    font-size: 0.9em;
}



.chat_audio_user_name {
    position: relative;
    display: inline-block;
    width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 16px;
    font-weight: 100;
}

.chat_msg_time_mine {
    /* position: relative;
    margin-top: 50px; */
    left: 100px;
    width: fit-content;
    white-space: nowrap;
}

.chat_msg_time {
    /* position: relative; */
    /*font-size: 12pt;*/
    font-size: 1em;
    font-weight: 100;
    font-family: "Adobe Heiti Std";
    
}


.chat_audio_player {
    display: none;
}

.chat_msg_content {
    /* height: 40px; */
    cursor: pointer;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    line-height: 30px;
}

.chat_msg_content.is_test {

    /* width: 230px; */
    display: inline-block;
    padding: 0px 5px;
    word-break: break-all;
    max-width: 379px;
    max-height: 120px;
    text-align: left;
    display: -webkit-box; 
    -webkit-box-orient: vertical;
     -webkit-line-clamp: 2;
      overflow: hidden;
}

.chat_msg_box .chat_msg_content {
    position: relative;
    background: #C4D0E1;
}

.chat_msg_box_mine .chat_msg_content {
    float: right;
    /* position: absolute; */
    top: 20px;
    right: 5px;
    background: #cff4ad;
}

.chat_msg_content span {
    display: inline-block;
    margin-left: 15px;
}

.chat_msg_content::before {
    position: absolute;
    top: 9px;
    border: 6px solid transparent;
    content: "";
}

.chat_msg_box .chat_msg_content::before {
    right: 100%;
    border-right-color: #C4D0E1;
}

.chat_msg_box_mine .chat_msg_content::before {
    left: 100%;
    border-left-color: #cff4ad;
}


.chat_audio_playing {
    position: absolute;
    top: 40%;
    right: 100%;
    margin-right: 2px;
    display: none;
    border-radius: 10px;
    width: 10px;
    height: 10px;
    background-color: red;
    vertical-align: middle;
}

/*录音记录end*/

/*下拉菜单start*/
.group_tcRecord_content {
    height: 500px;
}

/*下拉菜单end*/

/*预定义组录音记录end*/

/*预定义组媒体上传start*/

#group_mRecord_close_img {
    float: right;
    position: relative;
    top: 3px;
    left: 5px;
    /* margin-right: 10px; */
    width: 25px;
    height: 25px;
}

#task_mRecord_close_img {
    float: right;
    position: relative;
    top: 3px;
    left: 5px;
    display: none;
    /* margin-right: 10px; */
    width: 25px;
    height: 25px;

}



.group_media_list_item-select {
    background-color: #ebebeb;
}


/*预定义组媒体上传end*/



/*预定义组短消息记录 start*/

#group_msg_send_textarea {
    position: relative;
    top: 10px;
    display: inline !important;
    width: 230px !important;
    height: 60px !important;
    resize: none;

}




#group_msgChat_havenewmsg {
    position: absolute;
    right: 10px;
    display: none;
    margin: 10px;
    background-color: #fff;
    z-index: 200;
    border: 1px solid #E1E1E1;
    /* border-radius: 4px; */
    box-shadow: 1px 1px 1px rgba(0, 0, 0, .075);
}

#group_msgChat_havenewmsg ul {
    /* height: 30px; */
    margin: 0;
    padding: 5px 10px;
}

#group_msgChat_havenewmsg li:first-child {
    display: inline;
    vertical-align: super;
    border-right: 1px solid #e1e1e1;
    padding-right: 10px;
    color: #5FB878;
    vertical-align: baseline;
}

#group_msgChat_havenewmsg li:last-child {
    display: inline;
    vertical-align: top;
    padding-left: 10px;
    color: #000000;
}

#group_msgChat_havenewmsg li:last-child:hover {
    color: #e1e1e1;
}


/* 预定义组短消息记录 end */