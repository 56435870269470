.gm-style .gm-style-iw-c {
    padding: 0px !important;
    max-height: 900px !important;
}

.gm-style-iw-d {
    overflow: hidden !important;
}

.gm-style-iw-c button {
    /* top: -3px !important;
    right: 10px !important; */
    /* width: 45px !important;
    height: 45px !important; */
}

.gm-style-iw-c button img {
    /* width: 45px !important;
    height: 45px !important;
    margin: 0px !important; */
}



.gmnoprint {
    /* 谷歌地图卫星地图切换 */
    right: 60px;
}

.google-marker-label {
    position: absolute;
    z-index: 2;
    white-space: nowrap;
    cursor: default;
    padding: 3px;
    font-size: 12px;
    line-height: 14px;
    color: #000;
    background-color: #f5e6d3;
    border: 1px solid #efb589;
    top: 20px;
    left: 20px;
}

.text-marker-class {
    color: #fff;
    border: 1px solid #000;
    background-color: rgb(23, 145, 252);
    padding: 0px 5px;
    border-radius: 2px;
}

.text-marker-content {
    display: block;
    width: auto;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
}

#cejucontent {
    margin: 5px 20px 5px 5px
}

/* 百度地图  start */
.BMapLabel {
    max-width: none !important;
}

/* 百度地图 end*/