#map_wrapper,
#bMapGL_wrapper {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

#map_panel_content {
    position: fixed;
    background-color: white;
    max-height: 90%;
    overflow-y: auto;
    top: 10px;
    right: 10px;
    width: 280px;
    display: none;
}

.button-group {
    display: none;
}

.map_toolbar_sub {
    display: inline-block;
    float: left;
    z-index: 105;
    margin: 2px 0;
}

.view-monitoruser-note {
    min-width: 360px;
    /* _width: 360px; */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-right: 1px dashed #C4D0E1;
}

#map-op {
    /* width: calc(100% - 360px); */
    padding-left: 5px;
}

.map_user_status_chexbox {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
}

#map_toolbar_wrapper {
    position: absolute;
    bottom: 25px;
    left: 0px;
    font-size: 12px;
    padding: 10px;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    min-width: 360px;
    /* width: fit-content;可以实现元素收缩效果的同时，保持原本的block水平状态 */
}
#loginTips{
    position: absolute;
    height: 30px;
    width: 500px;
    background-color: #FEFF80;
    border-radius: 6px;
    top: 0px;
    left: 30%; 
    text-align: center;
    line-height: 30px;
}
.map_toolbar {

    position: relative;
    padding: 5px;
    border-radius: 4px;
    /* width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content; */
    /* height: 30px; */
    overflow: hidden;
    background-color: #fff;
    z-index: 105;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.historytrack_opbar_wrapper {
    display: none;
    position: absolute;
    right: calc(100%/2 - 325px);
    margin-top: 50px;

    width: 800px;
    height: 50px;
    z-index: 100;
}


/*获取历史轨迹start*/

#history-path-content {
    height: 240px;
    width: 390px;
    background-color: white;
    overflow: hidden;
    position: fixed;
    right: 50%;
    top: 30%;
    z-index: 201;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    display: none;
    box-shadow: 2px 2px 5px #C4D0E1;
}

.map_select {
    position: relative;
    right: 0px;
    /* height: 36px; */
    color: #000 !important;
    width: 110px;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    /* font-size: 14px; */
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    border: 1px solid #ccc;
    /* border-color: #ccc; */
    /* border-left-color: transparent !important; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    -webkit-appearance: none;
    background: url(../img/selectTab.79285d.png)85px 13px no-repeat #fff;
    cursor: pointer;
}

.opbar_shade {
    /* 遮蔽罩 */
    width: 100%;
    height: 100%;
    background-color: #000;
    padding: 17px 38px;
    z-index: 101;
    -moz-opacity: 0.6;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 6px;
}


.btn {
    color: #000 !important;
    font-size: 1em !important;
}

.map_opbar_speedandtime {
    position: relative;
    right: 5px;
    background-color: #fff;
    display: inline-block;
    vertical-align: middle;
}

#historytrack_time_contianer {
    right: 10px;
    width: 180px;
    height: 36px;
    vertical-align: middle;
    line-height: 36px;
    text-align: center;
}

#playHistorymode {
    width: 130px;
    background-position: 105px 13px;
}

#historyTrackSpeed {
    width: 70px;
    background-position: 45px 13px;
}

/*获取历史轨迹end*/

/* 地图标注start */

/* 信息窗体start */
.info {
    border: solid 1px silver;
    background-color: #fff;
}

.info div.info-top {
    position: relative;
    background: none repeat scroll 0 0 #F9F9F9;
    border-bottom: 1px solid #CCC;
    border-radius: 5px 5px 0 0;
}

.info div.info-top div {
    display: inline-block;
    color: #333333;
    font-size: 14px;
    font-weight: bold;
    line-height: 31px;
    padding: 0 10px;
}

.info div.info-top img {
    position: absolute;
    top: 10px;
    right: 10px;
    transition-duration: 0.25s;
}

.info div.info-top img:hover {
    box-shadow: 0px 0px 5px #000;
}

.info div.info-middle {
    font-size: 12px;
    padding: 5px;
    line-height: 20px;
}

.info div.info-bottom {
    height: 0px;
    width: 100%;
    clear: both;
    text-align: center;
}

.info-spanbtn {
    float: right;
    padding: 4px;
    margin-right: 4px;
    border: 1px solid #c9c9c9;
    border-radius: 2px;
    cursor: pointer;
}

.nav-button {
    height: 30px;
}

.info .qy_form_item textarea,
.info .qy_form_item input[type=text] {
    background-color: #fff;
    text-align: left;
    padding: 5px 10px;
}

#info-changepoint-btn {
    bottom: 0px;
    top: 10px;
}

#change-point-icon-info ul {
    list-style: none;
}

#change-point-icon-info li {
    height: 33px;
    margin-top: 2px;
    _margin-top: -3px;
}

#change-point-icon-info li a {
    float: left;
    /* display: block; */
    border: 1px solid #fff;
    width: 50px;
    height: 32px;
    text-align: center;
    /* margin: 0 11px 0 1px; */
    display: inline;
}

#change-point-icon-info li a.on,
#change-point-icon-info li a:hover {
    border: 1px solid #83A5E2;
    background-color: #EFF3FC;
}

.select-icon {
    overflow: hidden;
    background: url(../img/us_cursor.9517a2.png) no-repeat;
    display: inline-block;
}

.img-index {
    float: left;
    margin-top: 5px;
}

.us-cursor {
    width: 10px;
    height: 22px;
    margin-top: 5px;
}

.cursor-green {
    background-position: 0 0;
}

.cursor-blue {
    background-position: -10px 0;
}

.cursor-red {
    background-position: -20px 0;
}

.cursor-orange {
    background-position: -30px 0;
}

.cursor-purple {
    background-position: -40px 0;
}

.cursor-pink {
    background-position: -50px 0;
}

.us-mark {
    width: 18px;
    height: 26px;
    margin-top: 3px;
}

.mark-green {
    background-position: 0 -22px;
}

.mark-blue {
    background-position: -18px -22px;
}

.mark-red {
    background-position: -36px -22px;
}

.mark-orange {
    background-position: -54px -22px;
}

.mark-purple {
    background-position: -72px -22px;
}

.mark-pink {
    background-position: -90px -22px;
}

.us-flag {
    width: 16px;
    height: 19px;
    margin-top: 5px;
}

.flag-green {
    background-position: 0 -48px;
}

.flag-blue {
    background-position: -16px -48px;
}

.flag-red {
    background-position: -32px -48px;
}

.flag-orange {
    background-position: -48px -48px;
}

.flag-purple {
    background-position: -64px -48px;
}

.flag-pink {
    background-position: -80px -48px;
}

/* 信息窗体end */

/* 批量创建 */


/* 地图标注end */
.amap-overlay-text-container {
    max-width: 120px;
    font-size: 0.8em;
}


/* 居中对讲者start */
#map_talker_center {
    position: absolute;
    top: 50px;
    left: 70px;
    display: block;
    margin-bottom: 15px;
    width: fit-content;
    max-width: 165px;
    height: 35px;
    border-width: 0;
    border-radius: 3px;
    background-color: white;
    box-shadow: 0 2px 6px 0 rgba(114, 124, 245, .5);
}

/* 居中对讲者end */

/* 百度地图搜索start */
#baidu_map_search_container {
    position: absolute;
    top: 10px;
    left: 70px;
    display: block;
    /* margin-bottom: 15px; */
    width: fit-content;
    height: fit-content;
    /* max-width: 300px;
    height: 55px; */
    border-width: 0;
    border-radius: 3px;
    background-color: white;
    box-shadow: 0 2px 6px 0 rgba(114, 124, 245, .5);
}

#baidu_map_search_container .qy_input_group {
    width: 300px;
    margin: 0;
    padding: 0
}

#baidu_map_search_result {
    position: absolute;
    background-color: #fff;
}



#baidu_map_search_result ol {
    overflow: auto;
    max-height: 300px;
}

.clear-input {
    display: none;
    border: 0;
    /*去掉button按钮的棱角(边框)   */
    position: absolute;
    /*子元素的绝对定位    */
    right: 20%;
    transform: translate(0, 50%);
    /* 按钮的绝对定位的位置   */
    background: url(../img/icon_menu2_close.0bc2ce.png) no-repeat;
    /* 按钮的背景图片  */
    width: 25px;
    height: 25px;
    /* 按钮的大小 */
    background-size: 10px 10px;
    /* 背景图片的大小  */
}

#baidu_map_search_input:valid+.clear-input {
    display: block;
}/* 百度地图搜索end */
#video_in_map{
    position: absolute;
    top: 50px;
    right: 0px;
    height: 90%;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
   
}
.video_in_map_item{
    margin-top: 1%;
    height: 19%;
    width: 100%;
}
.video_map_content{
    height: 100%;
    width: 100%;
    background-color: #333333;
}
.video_map_text{
    margin-top: -20px;
    color: #fff;
    text-shadow: #000 1px 1px ;
    position: absolute;
    z-index: 999999;
}
#video_map_top{
    width: 100%;
    height: 20px;
    background-color: #C4D0E1;
}
#video_map_close{
 position: absolute;
top: 0px;
right: 0px;
color: red;
height: 20px;
width: 20px;
text-align: center;
line-height: 20px;
background-color: #F3F3F3;
font-size: large;
cursor: pointer;   
}

