/*一级菜单start*/


/*搜索相关start*/


.F-menu-0-search-time-search {
    height: 360px;
    width: 390px;
    display: none;
    position: fixed;
    background-color: white;
    z-index: 1;
    left: 50%;
    top: 30%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-shadow: 2px 2px 5px #C4D0E1;
}

.F-menu-0-search-time-search-header {
    height: 40px;
    background-color: #25344b;
    color: #fff;
    font-weight: 100;
}

.F-menu-0-search-time-search-title {
    position: relative;
    top: 7px;
    margin: 0px 30px;
    font-size: 1.15em;
}

.F-menu-0-search-time-search-close {
    position: relative;
    right: 15px;
    bottom: 5px;
    cursor: pointer;
    float: right;
}

.F-menu-0-search-time-search-time-hint {
    background-color: #b7b7b7;
    color: #fff;
    text-align: center;
}

.task_search_content_2,
.task_search_content_3,
.task_search_content_4 {
    margin-bottom: 5px;
    display: none;
}


.task_search_name_input {
    width: 170px;
}

.task_search_username_input {
    width: 170px;
}


.task-search-select {
    position: relative;
    right: 0px;
    float: right;
    ;
}

.task_search_content_plus_img {
    vertical-align: middle;
    width: 20px;
    height: 20px;
    position: relative;
    /* margin: 0px 5px 20px 5px; */
}

.task_search_content_minus_img {
    vertical-align: middle;
    width: 20px;
    height: 20px;
    position: relative;
    /* margin: 0px 5px 20px 5px; */
    display: none;
}


/*搜索相关end*/


/*任务列表相关start*/

.F-taskManagement-menu-content-main-show-content {
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
    width: 380px;
    height: 100%;
}


.F-menu-0-search-time-hint {
    background-color: #b7b7b7;
    color: #fff;
    text-align: center;
    height: 90px;
}

.F-menu-0-search-time-hint span {
    font-size: 1em;
    font-weight: 100;
}

.F-menu-0-search-time-close-btn {
    float: right;
    width: 20px;
    height: 20px;
    position: relative;
    top: 10px;
    right: 10px;
}

.F-menu-0-search-time-start-lable,
.F-menu-0-search-time-end-lable,
.F-menu-0-search-time-Rstart-lable,
.F-menu-0-search-time-Rend-lable {
    float: left;
    position: relative;
    left: 20px;
}

.F-menu-0-search-time-start-time {
    position: relative;
}

.F-menu-0-search-time-end-time {
    position: relative;
    right: 10px;
}

.F-menu-0-search-time-Rstart-time {
    position: relative;
    right: 25px;
}

.F-menu-0-search-time-Rend-time {
    position: relative;
    right: 25px;
}

.F-menu-0-tasklist-item-select {
    background-color: #ebebeb;
}


.task_item_starttm {
    font-size: 0.75em;
    position: absolute;
    bottom: 0px;
    left: 40px;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #999;
}

.F-menu-0-tasklist-item-fenge {
    position: relative;
    bottom: 27px;
    left: 130px;
    color: #999;
}

.Ftask_item_endtm {
    font-size: 0.75em;
    position: absolute;
    bottom: 0px;
    right: 20px;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #999;
}


/*任务列表相关end*/


/*页脚相关start*/





.F-menu-0-batchImport-blinker {
    display: none;
    position: fixed;
    z-index: 300;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.6);
    padding-top: 130px;
    color: black;
}

.F-menu-0-batchImport {
    position: relative;
    left: 30%;
    width: 1007px;
}

.F-menu-0-batchImport-header {
    height: 40px;
    background-color: #25344b;
    color: #fff;
    font-weight: 100;
}

.F-menu-0-batchImport-close {
    position: relative;
    float: right;
    cursor: pointer;
    right: 10px;
    top: 10px;
}

.F-menu-0-batchImport-title {
    position: relative;
    top: 7px;
    right: 7px;
    margin: 0px 30px;
    font-size: 1.15em;
}


.F-menu-0-batchImport-import {
    position: relative;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.F-menu-0-batchImport-Templatedownload {
    position: relative;
    width: 40px;
    height: 40px;
    cursor: pointer;
}


/*页脚相关end*/


/*一级菜单end*/


/*二级菜单start*/

#task_detial_page::-webkit-scrollbar {
    width: 0px
}


/*header start*/





.F-taskManagement-menu-1-startname {
    color: #999;
    font-size: 0.75em;
    font-family: "Adobe Heiti Std";
    position: relative;
    top: 45px;
    right: 165px;
}

.F-taskManagement-menu-1-header-time-guanlian {
    color: #999;
    font-size: 0.75em;
    font-family: "Adobe Heiti Std";
    position: relative;
    right: 150px;
    top: 45px;
}

.F-taskManagement-menu-1-endname {
    color: #999;
    font-size: 0.75em;
    font-family: "Adobe Heiti Std";
    position: relative;
    left: 150px;
    top: 22px;
}






/*headrt end*/


/*任务描述start*/

.F-taskManagement-menu-1-content {
    padding: 10px;
    height: 230px;
    overflow-y: auto;
}

.F-menu-1-taskstatus {
    position: relative;
    height: 20px;
    margin-bottom: 2px;
}

.F-menu-1-taskstatus-label {
    position: relative;
    margin-right: 10px;
}

.F-menu-1-taskType {
    position: relative;
    height: 20px;
    margin-bottom: 2px;
}

.F-menu-1-taskType-label {
    position: relative;
    margin-right: 10px;
}

.F-menu-1-Rstarttime {
    position: relative;
    height: 20px;
    margin-bottom: 2px;
}

.F-menu-1-Rendtime {
    position: relative;
    height: 20px;
    margin-bottom: 2px;
}

.F-taskManagement-menu-1-taskDescription {
    height: 110px;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}


/*任务描述end*/


/*参与人员start*/


.F-taskManagement-menu-1-attendants-delete {
    width: 20px;
    height: 20px;
    float: right;
    position: relative;
    right: 10px;
    top: 7px;
}

.F-taskManagement-menu-1-attendants-name {
    display: inline-block;
    width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}


/*参与人员end*/


/*二级菜单end*/


/*三级菜单start*/

#task_create_page::-webkit-scrollbar {
    width: 0px
}


/*创建任务start*/


#task_create_info input {
    text-align: left;
    width: 230px;
    background-color: #fff !important;
}

.task_create_infocontent {
    background-color: white;
    padding: 10px 20px;
}

.F-menu-2-createTask-taskname-label {
    font-size: 1em;
    position: relative;
}

.F-menu-2-createTask-taskDescription-label {
    font-size: 1em;
    position: relative;
}

#task_create_desc_textarea {
    margin: 10px 0px;
    width: 319px;
    text-align: left;
}

.F-menu-2-createTask-taskType-label {
    font-size: 1em;
    position: relative;
}





/*创建任务end*/




/*s三级菜单end*/



/*五级菜单start*/

#F-taskManagement-menu-4 {
    width: 360px;
    height: 100%;
    float: left;
    background-color: white;
    display: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    border-right: 1px solid #C4D0E1;
}

.F-menu-4-search {
    padding: 5px 10px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-bottom: 1px solid #C4D0E1;
    text-align: center;
    height: 40px;
    background-color: #f3f3f3;
}

.F-menu-4-search-control {
    display: inline-block;
    width: 310px;
}

.F-menu-4-search-label {
    font-size: 1.25em;
    color: #108ee9;
    position: relative;
    border: 5px;
}

.F-menu-4-search-filter-btn {
    cursor: pointer;
    width: 25px;
    height: 25px;
    position: relative;
}

.F-menu-4-search-icon {
    width: 25px;
    height: 25px;
    position: relative;
    bottom: 3px;
    left: 65px;
}

.F-menu-4-search-menu-close-btn {
    width: 25px;
    height: 25px;
    float: right;
    position: relative;
}

.F-menu-4-search-calender-search {
    box-shadow: 0 0 3px #ccc;
    height: 235px;
    width: 395px;
    display: none;
    position: fixed;
    background-color: white;
    left: 47%;
    top: 27%;
    -webkit-box-sizing: border-box;
    border-bottom: 1px solid #C4D0E1;
}

.F-menu-4-search-calender-search-title {
    width: 100%;
    height: 40px;
    color: #fff;
    background-color: #25344b;
}

.F-menu-4-search-calender-search-label {
    font-size: 1.25em;
    float: left;
    position: relative;
    left: 20px;
    top: 5px;
}

.F-menu-4-search-set-menu-title-close {
    width: 35px;
    height: 35px;
    float: right;
    cursor: pointer;
    position: relative;
}

.F-menu-4-search-calender-search-con {
    height: 225px;
    padding: 30px 40px;
}

.F-menu-4-search-calender-search-starttm-lable {
    font-size: 1.15em;
    position: relative;
    right: 10px;
    top: 5px;
}

.F-menu-4-search-calender-search-endtm-lable {
    font-size: 1.15em;
    position: relative;
    right: 10px;
    top: 30px;
}

#F-menu-4-search-calender-search-starttm,
#F-menu-4-search-calender-search-endtm {
    text-align: center;
    font-size: 0.6em;
    /* display: block; */
    height: 35px;
    width: 230px;
    line-height: 1.42857143;
    color: #555;
    background-color: #F3F3F3;
    border: 1px solid #fff;
    border: 1px solid #C4D0E1;
    font-size: 1em;
    font-family: "Microsoft YaHei";
    display: inline-block;
    float: right;
}

#F-menu-4-search-calender-search-info-btn {
    border: 1px solid #108ee9;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    color: white;
    background-color: #108ee9;
    margin: 0px auto;
    font-size: 18px;
    height: 35px;
    width: 320px;
    position: relative;
    top: 30px;
}


/*音内容start*/

#F-menu-4-recordings {
    height: 750px;
    padding: 10px 10px;
    overflow-x: hidden;
    overflow-y: auto;
}

.F-menu-4-recordings-message-box {
    width: 230px;
    height: 105px;
    padding-top: 10px;
}

.F-menu-4-recordings-message-headimage {
    width: 45px;
    height: 45px;
    position: relative;
}

.F-menu-4-recordings-content {
    padding-left: 15px;
    font-size: 1em;
    height: 90px;
    width: 185px;
    overflow: hidden;
    position: relative;
    bottom: 65px;
    left: 50px;
}

.F-menu-4-recordings-content-name {
    font-size: 1em;
    font-weight: 100;
    position: relative;
}

.F-menu-4-recordings-videoplayer {
    display: none;
}

.F-menu-4-recordings-box {
    background: #cff4ad;
    width: 150px;
    height: 40px;
    cursor: pointer;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    position: relative;
    line-height: 40px;
}

.F-menu-4-recordings-box span {
    display: inline-block;
    margin-left: 15px;
}

.F-menu-4-recordings-box::before {
    content: "";
    position: absolute;
    top: 9px;
    right: 100%;
    border: 6px solid transparent;
    border-right-color: #cff4ad;
}

.F-menu-4-recordings-playing {
    /* background: url(../img/channelSessionMenu/yuyin.png) 0 0 no-repeat;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    display: inline-block;
    position: absolute;
    top: 10%; */
    width: 10px;
    height: 10px;
    vertical-align: middle;
    display: inline-block;
    border-radius: 10px;
    background-color: red;
    display: none;
}

.F-menu-4-recordings-content-time {
    font-size: 12pt;
    font-weight: 100;
    position: relative;
    font-family: "Adobe Heiti Std";
}


/*录音内容end*/


/*五级菜单end*/