/*
 * 电子围栏
 */


/*电子围栏二级菜单2(创建围栏)start*/



.fence_user_type_select,
.fence_user_type_batch_select,
.fence_user_type_select {
    height: 24px;
    width: 90px;
    padding-left: 5px;
    background-position: 68px 8px;
    font-size: 14px;
}

.fence_user_type_batch_select {
    position: relative;
    bottom: 25px;
}



.fence_user_name {
    width: 160px;
}

/*电子围栏二级菜单2(创建围栏)end*/


/*电子围栏二级菜单3(围栏设置)start*/

.fence_set_circular_btn {
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: inline-block;
}


#fence_set_search_content {
    display: block;
    margin-top: 20px;
    padding: 0;
}



#fence_alert_wrapper {
    background-color: white;
    overflow-y: auto;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    position: relative;
}

#fence_alert_content {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 10px;
    overflow-x: hidden;
}


.fence_alert_name {
    width: 80px;
}


.fence_alert_info {
    color: #2b97e1;
    /* border: 1px solid #108ee9; */
    width: 120px;
    /* height: 36px; */
    text-align: center;
    line-height: 33px;
    /* border-radius: 5px; */
    /* font-size: 17px; */
    /* position: absolute; */
    /* top: 3px; */
    /* left: 110px; */
    float: right;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}


.fence_user_alert_type {
    padding: 2px;
    color: red;
    font-size: 17px;
    /* position: absolute; */
    /* top: 6px; */
    /* right: 3px; */
    float: right;
}

.fence_alert_delete_btn {
    position: relative;
    top: 5px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    float: right;
}

#fence_alert_footer {
    height: 110px;
    background-color: white;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    text-align: center;
    display: block;
    padding-top: 10px;
    /* background-color: #f8f8f8; */
}

#edit_fence_color_picker {
    position: relative;
    left: 50px;
}

/*电子围栏二级菜单3(围栏设置)end*/

/* 信息窗体 */
.map-enclosure-user-list {
    overflow-x: hidden;
    overflow-y: auto;
    max-width: 315px;
    max-height: 180px;
}