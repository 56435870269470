/*
 * 组织结构
 */

#root {
    border: 1px solid red;
    display: inline-block;
    width: 270px;
    height: 500px;
    overflow: auto;
    background-color: white;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.gTree-root {
    margin: 0px;
    padding: 0px;
    list-style: none;
    min-width: 150px;
    max-width: 380px;
    overflow: visible;
}

.gTree-root ul {
    margin: 0px;
    margin-left: 20px;
    padding: 0px;
    list-style: none;
    white-space: nowrap;
}

.gTree-node-name-label {
    width: 80px;
    height: 25px;
}

.gTree-node-name {
    white-space: nowrap;
    cursor: pointer;
    font-family: "Microsoft YaHei";
    font-size: 1.12em;
    margin: 15px;
    position: relative;
    bottom: 110%;
    left: 8%;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 150px;
    display: block;
}

.gTree-parent-fold-btn {
    vertical-align: middle;
    margin-left: 12px;
    /* margin-top: 5px; */
    cursor: pointer;
    width: 20px;
    height: 20px;
    position: relative;
    top: 2px;
}

.gTree-parent-group-add-btn,
.gTree-parent-group-edit-btn,
.gTree-parent-group-create-btn {
    display: none;
    vertical-align: middle;
    margin-left: 8px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    position: relative;
    bottom: 75px;
    left: 180px;
}

.gTree-root li ul {
    display: none;
}

.gTree-parent,
.gTree-child {
    padding: 6px 5px;
    white-space: nowrap;
    height: 30px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    height: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 280px;
}

.gTree-parent:hover,
.gTree-child:hover {
    background-color: #BFEFFF;
}

.gTree-parent {
    position: relative;
}

.gTree-hover-bg-color {
    background-color: #BFEFFF;
}

.gTree-edit-content {
    border: 1px solid orange;
    padding: 5px;
    background-color: white;
    position: absolute;
    z-index: 1;
    display: none;
    right: 10px;
    top: 30px;
}

.gTree-edit-content-title {
    font-size: 12px;
}

.gTree-edit-content-input-name {
    width: 200px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.gTree-edit-content-save-btn {
    border: 1px solid #C4D0E1;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    color: white;
    background-color: #b4a692;
    margin: 0px auto;
    font-size: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 55px;
    padding-right: 55px;
}

.gTree-node-user-online {
    vertical-align: middle;
    margin-right: 2px;
}

.gTree-node-user-open-jiangting-btn,
.gTree-node-user-close-jiangting-btn,
.gTree-node-user-delete-btn {
    cursor: pointer;
    vertical-align: middle;
    margin-right: 2px;
    margin-left: 2px;
}

.gTree-node-hide {
    display: none;
}