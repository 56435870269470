/* 
 *考勤管理
 */

#nav_attendance_icon {
    background-position: -249px 1px;
}

.attend_td {
    padding: 5px;
    margin: 0;
}

.attend_td span {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}

.attend_name {
    width: 120px;
}

.attend_time {
    width: 170px;
}

.attend_op {
    width: 95px;
}

.attend_loc {
    /* flex: 1; */
    width: 310px;
}

.attend_item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
    padding: 10px 0px;
}

#attendance_list_content .attend_item:hover {
    background-color: #f2f2f2;
}

#attendance_list_header {
    background-color: #f2f2f2;
}