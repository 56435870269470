/*一级菜单start*/



/* header start*/


.vc_add_user_search_tip {
    font-size: 1.15em;
    color: #999;
    position: relative;
}


/*header end*/





/*footer start*/



/*footer end*/


/*一级菜单end*/


/*二级菜单start*/

#vc_content {
    width: 100%;
    height: 99%;
    position: relative;
    z-index: 199;
}

.vc_subwindow_container {
    width: 22%;
    height: auto;
    position: absolute;
    right: 0px;
    top: 0px;
    overflow-y: scroll;
    
}
.vc_subwindow_container::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
    }
.vc_subwindow_item {
    border: 1px solid #C4D0E1;
    height: 120px;
    margin: 5px 20px;
    position: relative;
    width: calc(100% - 40px);
    width: -moz-calc(100% - 40px);
    width: -webkit-calc(100% - 40px);
    float: right;
    display: none;
    background-color: #000;
}

.vc_subwindow_name_content {
    color: #fff;
    text-shadow: #000 1px 1px ;
    height: 30px;
    width: 101px;
    display: inline-block;
    position: absolute;
    bottom: -5px;
    font-size: 1em;
    left:  10px;
}

.vc_subwindow_name_content span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    width: 100%;
}

#vc_bigwindow_name_span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    width: 150px;
    position: absolute;
}

#vc_bigwindow_content {
    border: 1px solid #C4D0E1;
    /* height: 712px;
       width: 500px; */
    height: 90%;
    width: 75%;
    
    position: relative;
    
}

.vc_video_content {
    margin: 0 auto;
}

.vc_video_mine_content {
    border: 1px solid #C4D0E1;
    color: #C4D0E1;
    height: 210px;
    width: 175px;
    display: inline-block;
    float: right;
    margin: 10px;
    position: absolute;
    top: 0px;
    right: 0px
}



#vc_bigwindow_toolbar {
    /* border: 1px solid #C4D0E1; */
    color: #C4D0E1;
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 0px;
    /* margin: 10px; */
    z-index: 1
}

.vc_bigwindow_info_content {
    width: 500px;
}

.vc_bigwindow_info_span {
    display: inline-block;
    position: relative;
    margin: 10px;
    text-shadow: #000 1px 1px ;
}

.vc_bigwindow_toolbar_center {
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    /* IE 9 */
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    /* Safari and Chrome */
}

#vc_hangup_btn {
    border: 1px solid #108ee9;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    color: white;
    background-color: #108ee9;
    margin: 0px 10px;
    display: inline-block;
    padding: 6px 20px;
    position: relative;
    bottom: 5px;
}


.videocentent-big {
    height: 100%;
    background-color: #000;
}

.videocentent {
    width: 214px;
    height: 120px;
    
}

.vc_bigwindow_mute_img {
    float: right;
    position: relative;
    width: 25px;
    height: 25px;
    margin: 11px 0px;
    bottom: 40px;
}

.vc_fullscreen_img {
    float: right;
    position: relative;
    width: 25px;
    height: 25px;
    margin: 11px 20px;
    bottom: 40px;
}

#vc_enlarge_wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 20000000;
}

.vc_subwindow_header {
    margin: 0px 20px
}

.vc_subwindow_content {
    width: 100%;
    display: block;
}

#vc_subwindow_add {
    width: 45px;
    height: 45px;
    margin: auto;
  
}

.vc_subwindow_close_img {
    position: absolute;
    right: 0px;
    top: 0px;
    margin: 5px;
    width: 15px;
    height: 15px
}

.vc_subwindow_mute_img {
    position: absolute;
    right: 0px;
    bottom: 0px;
    margin: 5px;
    width: 15px;
    height: 15px
}

.vc_bigwindow_volume_content {
    width: 100px;
    display: inline;
    height: 10px;
    position: relative;
    float: right;
    bottom: 15px;
    right: 15px;
    display: inline
}

.volume_progressbar {
    width: 100px;
    height: 2px;
    background-color: #6d7a8b;
}
#videoOne{
    display: none;
    height: 100%;
}
#videoTwo{
    display: none;
    width: 100%;
    height: 100%;
    
}
.video-four-dv{
 width: 175px;
 height: 215px;
 border: solid 1px #999;

 background-color: #000;
}
.video-nine-dv{
    width: 175px;
    height: 215px;
   border: solid 1px #999;

    background-color: #000;
   }
   #videoThree{
    display: none;
    width: 100%;
    height: 100%;
    
}
.video-sixteen-dv{
    width: 175px;
    height: 215px;
   border: solid 1px #999;

    background-color: #000;
   }
   #videoFour{
    display: none;
    width: 100%;
    height: 100%;
    
}
.video-fun-dv{
    height: 30px;
    width: 100%;
    background-color: #F3F3F3;
   
    display: flex;
    flex-direction: row;
}
#changeScreen{
    height: 30px;
    width: 90px;
}
#addVideoUser{
    height: 500px;
    width: 300px;
    position: absolute;
    top:50px;
    right: 5px;
    overflow-y: scroll;
    z-index: 998;
    display: none;
    background-color: #fff;
    padding-top: 20px;
    border: solid 1px #C4D0E1;
}
#addVideoUser-close{
    position: absolute;
    top: 0px;
    right: 0px;
    color: red;
    height: 20px;
    width: 20px;
    text-align: center;
    line-height: 20px;
    background-color: #F3F3F3;
    font-size: large;
    cursor: pointer;
}
#addVideoUser-add{
    display: none;
    position: absolute;
    height: 30px;
    width: 80px;
    border: solid 1px #108EE9;
    line-height: 28px;
    border-radius: 8px;
    text-align: center;
    color:  #108EE9;
    z-index: 999;
}
#addVideoUser-add:hover{
    color: #fff;
    background-color: #108ee9;
}
.bps_content_me{
  height: 30px;
  line-height: 30px;
  margin-left: 20px;
  z-index: 9999;
  position: relative;
  top:-25px;
  color:#fff;
  text-shadow: #000 1px 1px ;
}
.bps_content_me2{
    height: 30px;
    line-height: 30px;
    margin-left: 20px;
    z-index: 9999;
    position: relative;
    top:-25px;
    color:#fff;
    text-shadow: #000 1px 1px ;
  }
.bps_content_he{
    height: 30px;
    line-height: 30px;
    margin-left: 20px;
    z-index: 9999;
    position: relative;
    top:-25px;
    color:#fff;
    text-shadow: #000 1px 1px ;  
}
.video_zero_state{
    height: 20px;
    line-height: 20px;
    margin-left: 20px;
    z-index: 9999;
    position: relative;
    top:177px;
    color:#fff;
    text-shadow: #000 1px 1px ; 
    overflow:hidden;
	white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align:top;
}
.bps_content_he{
    overflow:hidden;
	white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align:top;
}
#videoZero{
overflow-y: scroll;

}
#videoZero::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
    }
.video_zero_item{
    height: 187px;
    width: 100%;
    margin-top: 8px;
}
/*二级菜单end*/