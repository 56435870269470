/*助手菜单*/



/*助手主菜单start*/


/*搜索栏start*/

.search_timepicker_img {
    vertical-align: middle;
    float: right;
    width: 33px;
    height: 33px;
    position: relative;
    left: 3px;
    bottom: 2px;
}

/*搜索栏end*/


/*成员列表start*/

.unread_icon {
    position: relative;
    left: 45px;
    bottom: 60px;
    display: none;
    display: inline-block;
}

/*成员列表end*/

/*助手主菜单end*/


/*菜单（勿删但不用此界面了,现在用来做界面滑动用来）start*/

.A-hp-m2-content {
    border: 1px solid #ebebeb;
    background-color: white;
    width: 350px;
    height: 100%;
}


/*菜单（勿删但不用此界面了）end*/


/*助手二级菜单start*/



.helper_fence_detial_content {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    margin-bottom: 5px;
    position: relative;
}

#setAlarmRingType {
    width: 95px;
    background-position-x: 75px;
}


/*媒体记录在消息助手中的显示start*/

.detial_picture {
    width: 355px;
    /* height: 270px; */
    cursor: pointer;
}



.detial_audio_time {
    color: #6d7a8b;
    position: relative;
    bottom: 230px;
    left: 150px;
}

.detial_audio_download {
    position: relative;
    position: relative;
    bottom: 255px;
    width: 30px;
    height: 30px;
    /* margin: 10px; */
    left: 320px;
}

.detial_audio_downloadswitch {
    position: relative;
}

.detial_msginfo_content {
    position: relative;
}

.detial_msginfo_content span {
    font-size: 1em;
    position: relative;

}



.detial_msginfo {
    word-break: break-all;
    word-wrap: break-word;
}

.media_enlarge_exit {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 2.5em;
    font-weight: bold;
    transition: 0.3s;
}

.media_enlarge_picture_content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 500px;
    /* max-height: 600px; */
}

.media_enlarge_picture_content {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
}


.media_enlarge_shade {
    display: none;
    /* Hidden by default */
    position: fixed;
    /*position: absolute;*/
    /* Stay in place */
    z-index: 180;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.9);
    /* Black w/ opacity */
}


/*媒体记录在消息助手中的显示end*/


/*录音播放start*/

.detial_audio_bg {
    width: 360px;
    height: 280px;
    cursor: pointer;
}

.detial_audio_bg2 {
    width: 145px;
    height: 80px;
    position: relative;
    bottom: 160px;
    left: 100px;
}

.detial_audio_pass {
    position: relative;
    bottom: 300px;
    left: 130px;
}

.db {
    display: block;
}

.weixinAudio {
    line-height: 1.5;
}

.detial_audio_area {
    display: inline-block;
    width: 360px;
    vertical-align: top;
    margin: 0px 1px 0px 0;
    font-size: 0;
    position: relative;
    bottom: 80px;
    font-weight: 400;
    text-decoration: none;
    -ms-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
}

.detial_audio_progress_wrap {
    background-color: #020202;
    overflow: hidden;
    bottom: 5px;
    height: 5px;
}

.audio_play_area {
    float: left;
    margin: 9px 22px 10px 5px;
    font-size: 0;
    width: 18px;
    height: 25px;
}

.playing .audio_play_area .icon_audio_default {
    display: block;
}

.audio_play_area .icon_audio_default {
    /* background: transparent url(img/iconloop.png) no-repeat 0 0; */
    width: 18px;
    height: 25px;
    vertical-align: middle;
    display: inline-block;
    -webkit-background-size: 54px 25px;
    background-size: 54px 25px;
    background-position: -36px center;
}

.audio_play_area .icon_audio_playing {
    /* background: transparent url(img/iconloop.png) no-repeat 0 0; */
    width: 18px;
    height: 25px;
    vertical-align: middle;
    display: inline-block;
    -webkit-background-size: 54px 25px;
    background-size: 54px 25px;
    -webkit-animation: audio_playing 1s infinite;
    background-position: 0px center;
    display: none;
}

.detial_audio_area .pic_audio_default {
    display: none;
    width: 18px;
}

.tips_global {
    color: #8c8c8c;
}

.detial_audio_area .audio_length {
    float: right;
    font-size: 0.85em;
    margin-top: 3px;
    margin-left: 1em;
}

.audio_info_area {
    overflow: hidden;
}

.detial_audio_area .audio_title {
    font-weight: 400;
    font-size: 1.065em;
    margin-top: -2px;
    margin-bottom: -3px;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
}

.detial_audio_area .audio_source {
    font-size: 0.875em;
}

.detial_audio_area .detial_audio_progress_bar {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #108ee9;
    height: 5px;
}

.playing .audio_play_area .icon_audio_default {
    display: none;
}

.playing .audio_play_area .icon_audio_playing {
    display: inline-block;
}

@-webkit-keyframes audio_playing {
    30% {
        background-position: 0px center;
    }

    31% {
        background-position: -18px center;
    }

    61% {
        background-position: -18px center;
    }

    61.5% {
        background-position: -36px center;
    }

    100% {
        background-position: -36px center;
    }
}


/*录音播放end*/


/*视频播放start*/



.detial_video {
    width: 360px;
    height: 240px;
    position: relative;
}

.detial_video_toolbar {
    height: 40px;
    width: 360px;
    background-color: #25344b;
    position: relative;
    bottom: 95px;
}

.detial_video_pass {
    position: relative;
    bottom: 150px;
    left: 150px;
}

.detial_video_start {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: relative;
    top: 10px;
    margin-left: 10px;
}

.detial_video_progressbar {
    width: 190px;
    height: 5px;
    background-color: #6d7a8b;
    position: relative;
    bottom: 3px;
    left: 50px;
}



.volume_current_progress {
    width: 10px;
    height: 10px;
    position: absolute;
    cursor: pointer;
    right: -0px;
    background-color: #108ee9;
    top: -4px;
    border-radius: 50%;
}

.volume_progress {
    border-radius: 3px 0 0 3px;
    height: 2px;
    cursor: pointer;
    background-color: #108ee9;
    position: absolute;
    top: 0;
    left: 0;
}

.detial_video_progress {
    border-radius: 3px 0 0 3px;
    height: 5px;
    cursor: pointer;
    background-color: #108ee9;
    position: absolute;
    top: 0;
    left: 0;
}

.detial_video_current_progress {
    width: 20px;
    height: 20px;
    position: absolute;
    cursor: pointer;
    right: -20px;
    background-color: #108ee9;
    top: -7px;
    border-radius: 50%;
}

.media_video_progressbar_move {
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
    left: -10px;
    bottom: 7px;
}

.detial_video_volume {
    position: relative;
    left: 265px;
    bottom: 62px;
    width: 20px;
}

.detial_video_volumeswitch {
    width: 20px;
    height: 20px;
}

.vc_bigwindow_volume_logo {
    width: 20px;
    height: 20px;
    float: right;
    position: relative;
    right: 115px;
    bottom: 9px;
}

.detial_video_volumebar {
    position: relative;
    bottom: 195px;
    left: 260px;
    width: 26px;
    height: 100px;
    background: #f1f2f2;
    border-radius: 4px;
    cursor: pointer;
    display: none;
}

.detial_video_volumebar:after {
    content: "";
    display: block;
    position: relative;
    left: 5px;
    top: 20px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #f1f2f2;
}

.detial_video_volumebar .detial_video_volumewrap {
    background: #a2a7aa;
    width: 8px;
    height: 80px;
    position: relative;
    top: 20px;
    left: 9px;
    border-radius: 4px;
}

.detial_video_volumebar .detial_video_volumeprogress {
    background: none;
}

.detial_video_volumebar .detial_video_progress_bar {
    position: absolute;
    bottom: 0px;
    right: 0px;
    border-radius: 4px;
    background-color: #108ee9;
}

.detial_video_fullscreen {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: relative;
    bottom: 20px;
    left: 300px;
}

#media_video_qp {
    font-size: 20px;
    color: #fff;
    /* float:right;
    cursor: pointer;
    position: relative;
    top: 120px;
    left: 10px;*/
}

.on {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
}

.detial_video_download {
    position: relative;
    left: 330px;
    bottom: 41px;
}

.detial_video_downloadswitch {
    width: 20px;
    height: 20px;
}


/*视频播放end*/


/*助手二级菜单end*/